import React from 'react';
import styled from 'styled-components';

const LeadSubmittedContainer = styled.div`
  max-width: 1086px;
  margin: 30px auto;

  h1 {
    text-align: center;
  }
`;

const LeadSubmitted = () => (
  <LeadSubmittedContainer>
    <h1>The lead has been submitted, thank you!</h1>
    <a href="/">
      <button type="button">Return to form</button>
    </a>
  </LeadSubmittedContainer>
);

export default LeadSubmitted;
